import React, {useState} from "react";
import { StyleSheet, css } from 'aphrodite';

import Toggle from 'react-toggle'

import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

function SettingsModal(props) {

  const updateTimerEnabled = () => {
    props.updateRoomSettings({ timer_enabled: !props.timerEnabled});
  }

  const updateTimerMinutes = (e) => {
    if (e.target.value === "" || e.target.value === null || e.target.value === undefined) { return };
    var updatedMinutes = parseInt(e.target.value);
    props.updateRoomSettings({ timer_duration: (updatedMinutes * 60 + props.timerDuration % 60) });
  }

  const updateTimerSeconds = (e) => {
    if (e.target.value === "" || e.target.value === null || e.target.value === undefined) { return };
    var updatedSeconds = parseInt(e.target.value);
    var oldSeconds = props.timerDuration % 60;
    props.updateRoomSettings({ timer_duration: (props.timerDuration - oldSeconds + updatedSeconds % 60) });
  }

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Settings
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Text className={css(styles.helperText)}>
          Show a countdown timer
        </Form.Text>
        <Form.Group as={Row}>
          <Col sm="6">
            <Form.Label>
              Timer
              <strong>{props.timerEnabled ? " ON" : " OFF"}</strong>
            </Form.Label>
          </Col>
          <Col sm="6" className={css(styles.inputColumn)}>
            <Toggle
              checked={props.timerEnabled}
              onChange={updateTimerEnabled}
              name='timeEnabled'
              value='yes'/>
          </Col>
        </Form.Group>
        {props.timerEnabled ?
          (<Form.Group as={Row}>
            <Col sm="6">
              <Form.Group>
                <Form.Label>
                  Mins
                </Form.Label>
                <Form.Control
                 type="number"
                 name="minutes"
                 defaultValue={Math.floor(props.timerDuration/60)}
                 onBlur={updateTimerMinutes}
                 />
              </Form.Group>
            </Col>
            <Col sm="6">
              <Form.Group>
                <Form.Label>
                  Secs
                </Form.Label>
                <Form.Control
                 type="number"
                 name="seconds"
                 defaultValue={props.timerDuration % 60}
                 onBlur={updateTimerSeconds}
                 />
              </Form.Group>
            </Col>
          </Form.Group>) : (null)
        }
      </Modal.Body>
    </Modal>
  );
}

// <Toggle
//   checked={enforceTimer}
//   onChange={setEnforceTimer}
//   name='enforceTimer'
//   value='yes'/>

const styles = StyleSheet.create({
  helperText: {
    paddingLeft: "0px",
  },
  inputColumn: {
    display: "flex",
    justifyContent: "flex-end",
  }
})

export default SettingsModal
