import React from "react"
import PropTypes from "prop-types"
import { StyleSheet, css } from 'aphrodite';

import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


class Board extends React.Component {
  constructor(props) {
    super(props);
  }

  createBoard = (data, openCard, spyMasterMode, complete) => {
    return data.map(function (rowOfData) {
      return (<Row className={css(styles.row)}>
        {
          rowOfData.map(function (card) {
            const team = card.team;
            const cardOpened = card.opened_by !== null && card.opened_by !== undefined;

            let cardClassName = cardOpened ? styles.openedNeutralGameCard : (spyMasterMode || complete ? styles.neutralSpyMasterCard : null);

            if (team == "red") {
              cardClassName = cardOpened ? styles.openedRedGameCard : (spyMasterMode || complete ? styles.redSpyMasterCard : null);
            } else if (team == "blue") {
              cardClassName = cardOpened ? styles.openedBlueGameCard : (spyMasterMode || complete ? styles.blueSpyMasterCard : null);
            } else if (team == "assassin") {
              cardClassName = cardOpened ? styles.openedAssassinGameCard : (spyMasterMode || complete ? styles.assassinSpyMasterCard : null);
            }

            const addAdditionalCardClassName = cardOpened || spyMasterMode || complete;

            return (
              <Col className={css(styles.column)} md={2}>
                <Card
                  className={css(styles.card, addAdditionalCardClassName ? cardClassName : null, addAdditionalCardClassName ? styles.disabled : null)}
                  onClick={() => openCard(card)}
                >
                  <Card.Body className={css(styles.cardBody)}>{card.word}</Card.Body>
                </Card>
              </Col>
            )
          })
        }
      </Row>)
    })
  }

  render () {
    const {data, openCard, spyMasterMode, complete} = this.props;

    return (
      <div className={css(styles.container)}>
        {this.createBoard(data, openCard, spyMasterMode, complete)}
      </div>
    );
  }
}

Board.propTypes = {
  heading: PropTypes.string
};

const styles = StyleSheet.create({
  disabled: {
    pointerEvents: "none",
    cursor: "not-allowed",
  },
  container: {
    width: "100%",
    textAlign: "center",
  },
  row: {
    display: "flex",
    justifyContent: 'center',
  },
  column: {
    paddingRight: "0px",
    paddingLeft: "0px",
  },
  card: {
    margin: '5px',
    minHeight: '100px',
    backgroundColor: 'white',
    cursor: "pointer",
  },
  cardBody: {
    margin: '0px',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    textTransform: 'uppercase',
  },
  openedRedGameCard: {
    backgroundColor: "#ff584a",
    color: "white"
  },
  redSpyMasterCard: {
    backgroundColor: "white",
    color: "#ff584a",
    fontWeight: "600",
  },
  openedBlueGameCard: {
    backgroundColor: "#4a95ff",
    color: "white"
  },
  blueSpyMasterCard: {
    backgroundColor: "white",
    color: "#4a95ff",
    fontWeight: "600",
  },
  openedNeutralGameCard: {
    opacity: "0.5",
    color: "black"
  },
  neutralSpyMasterCard: {
    color: "black",
    fontWeight: "600",
  },
  openedAssassinGameCard: {
    backgroundColor: "black",
    color: "white",
  },
  assassinSpyMasterCard: {
    backgroundColor: "black",
    color: "white",
    fontWeight: "700",
  },
});

export default Board
