import React, { Component } from 'react';

class AppWebSocket extends Component {
  componentDidMount() {
    let url = window.location.href.split("/");
    this.props.cableApp.room = this.props.cableApp.cable.subscriptions.create({
      channel: 'RoomsChannel',
      room: url[url.length -1]
    },
    {
      received: (updatedData) => {
        this.props.updateGame(updatedData)
      }
    })
  }

  render() {
    return (
      <div></div>
    )
  }
}

export default AppWebSocket
