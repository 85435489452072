import React from "react";
import PropTypes from "prop-types";
import actionCable from "actioncable";
import axios from "axios";
import { StyleSheet, css } from 'aphrodite';

import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faHourglassStart, faHandPaper, faMugHot } from '@fortawesome/free-solid-svg-icons'
import Confetti from 'react-confetti'
import Countdown from 'react-countdown';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import Board from './Board';
import SettingsModal from './SettingsModal';
import AppWebSocket from './AppWebSocket';
import 'react-toggle/style.css'
import 'bootstrap/dist/css/bootstrap.min.css';

const CableApp = {}
const socketConnectionType = window.location.host === 'localhost:3000' ? 'ws' : 'wss';
CableApp.cable = actionCable.createConsumer(socketConnectionType + '://' + window.location.host + '/cable')

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // synced remotely with sockets
      turn: props.turn,
      board: JSON.parse(props.data),
      gameId: props.gameId,
      roomIdentifier: props.roomIdentifier,
      showGif: false,
      gif: "",
      winner: null,
      timerEnabled: props.timerEnabled,
      timerDuration: props.timerDuration,
      timerEndTime: Date.parse(props.timerEndTime),
      //only locally accessed
      spyMasterMode: false,
      showSettingsModal: false,
    }
  }

  // ###############################################
  // API CALLS
  // ###############################################

  makeNextTurnRequest = (card) => {
    const {turn, gameId} = this.state;
    const previousTurn = turn;
    const cardPresent = card !== null && card !== undefined;

    const headers = { 'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").getAttribute("content") }
    const data = {
      card: {
        word: cardPresent ? card.word : null,
        opened_by: turn,
      }
    }

    axios.put(location.protocol + '//' + window.location.host + '/game_sessions/' + gameId.toString() + '/open_card',
      data, { headers: headers
    }).then(response => {
      const {board, turn, gameId, winner, gif, showGif} = response.data;
      const timerEndTime = Date.parse(response.data.timerEndTime);
      // if (previousTurn !== turn) { this.refreshTimer(this.state.timerDuration); }

      this.showGifs(showGif);
      this.setState({board, turn, gameId, winner, gif, timerEndTime});
    });
  }


  nextGame = () => {
    axios.post(location.protocol + '//' + window.location.host + '/game_sessions/' + this.state.gameId.toString() + '/next_game',
      {}, { headers: { 'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").getAttribute("content") } })
      .then(response => {
        const {board, turn, gameId, winner} = response.data;
        this.setState({board, turn, gameId, winner});
      });
  }

  updateRoomSettings = (data) => {
    axios.put(location.protocol + '//' + window.location.host + '/rooms/' + this.state.roomIdentifier + '/',
      data, { headers: { 'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").getAttribute("content") } })
      .then(response => {
        const {timerEnabled, timerDuration, roomIdentifier} = response.data;
        const timerEndTime = Date.parse(response.data.timerEndTime);
        this.setState({timerEnabled, timerDuration, roomIdentifier, timerEndTime});
      });
  }

  // ###############################################
  // SOCKET LOGIC
  // ###############################################

  updateGame = (updatedData) => {
    const board =          updatedData.board || this.state.board;
    const turn =           updatedData.turn || this.state.turn;
    const gameId =         updatedData.gameId || this.state.gameId;
    const winner =         updatedData.winner || this.state.winner;
    const gif =            updatedData.gif || this.state.gif;
    const showGif =        updatedData.showGif != null ? updatedData.showGif : this.state.showGif;
    const roomIdentifier = updatedData.roomIdentifier || this.state.roomIdentifier;
    const timerEnabled =   updatedData.timerEnabled != null ? updatedData.timerEnabled : this.state.timerEnabled;
    const timerDuration =  updatedData.timerDuration || this.state.timerDuration;
    const timerEndTime =   updatedData.timerEndTime != null ? Date.parse(updatedData.timerEndTime) : this.state.timerEndTime;
    const newGameStarted = updatedData.newGameStarted || false;
    const spyMasterMode = newGameStarted ? false : this.state.spyMasterMode;

    this.showGifs(showGif);
    this.setState({
      board, turn, gameId, winner, gif, roomIdentifier,
      timerEnabled, timerDuration, timerEndTime, spyMasterMode,
    });
  }

  // ###############################################
  // STATE FUNCTIONS
  // ###############################################

  openCard = (card) => {
    let newBoard = this.state.board;
    for (var i = 0; i < newBoard.length; i++) {
      let row = newBoard[i];
      for (var j = 0; j < row.length; j++) {
        let boardCard = row[j];
        if (boardCard.word === card.word) {
          this.makeNextTurnRequest(card, this.state.turn)
        }
      }
    }

    return newBoard;
  }

  unopenedCardCount = (team) => {
    var board = this.state.board;
    var count = 0;

    for (var i = 0; i < board.length; i++) {
      let row = board[i];
      for (var j = 0; j < board.length; j++) {
        let card = row[j];
        if (card.team === team && card.opened_by === null) {
          count++;
        }
      }
    }

    return count;
  }

  endTurn = () => {
    this.makeNextTurnRequest(null, this.state.turn);
  }

  toggleSpyMaster = () => {
    this.setState({spyMasterMode: !this.state.spyMasterMode});
  }

  showGifs = (show) => {
    this.setState({showGif: show}, () => {
      setTimeout(() => {
        show ? this.setState({showGif: false}) : null;
      }, 4000);
    });
  }

  showCopied = () => {
    this.setState({copied: true}, () => {
      setTimeout(() => {
        this.setState({copied: false});
      }, 4000);
    });
  }

  hideGifs = () => {
    this.showGifs(false);
  }

  setShowSettingsModal = (showSettingsModal) => {
    this.setState({showSettingsModal});
  }

  refreshTimer = (durationInSeconds) => {
    const timerEndTime = Date.now() + durationInSeconds * 1000;
    this.setState({timerEndTime});
  }

  render () {
    const {
      turn, board, showGif, gif, winner, spyMasterMode, timerEndTime,
      showSettingsModal, timerEnabled, timerDuration, roomIdentifier, copied
    } = this.state;

    const gameIsComplete = winner === "red" || winner === "blue";

    const textColorStyle = turn === "red" ? styles.redText : styles.blueText;

    const timerRenderer = ({ hours, minutes, seconds, completed }) => {
      if (completed) {
        return (
          <div className={css(styles.timerTextContainer)}>
            <FontAwesomeIcon icon={faHandPaper}/>
            <p className={css(styles.timerText, styles.boldCurrentTeamText)}>{"Your time is up"}</p>
          </div>
        )
      } else {
        return (
          <div className={css(styles.timerTextContainer)}>
            <FontAwesomeIcon icon={faHourglassStart}/>
            <p className={css(styles.timerText)}>{minutes}:{(seconds.toString()).padStart(2, '0')}</p>
          </div>
        )
      }
    };

    return (
      <Container fluid={"lg"} className={css(styles.container)}>
        {gameIsComplete ? <Confetti
                            width={window.innerWidth}
                            height={window.innerHeight}
                            colors={winner === "red" ? [styles.redText._definition.color] : [styles.blueText._definition.color]}
                            /> : null
        }
        <Row className={css(styles.bar, styles.topBar)}>
          <Col md={{ span: 3, offset: 1 }}>
            {showGif ? (<Image className={css(styles.gifImage)} src={gif} rounded fluid/>) : (null)}
            {!showGif ? (
              <div className={css(styles.clickToCopyContainer)}>
                <CopyToClipboard text={"https://subtlecurrygames.com/rooms/" + roomIdentifier}
                  onCopy={() => this.showCopied()}>
                  <div>
                    <u>Invite Others</u> { copied ? (<small className={css(textColorStyle)}> Copied link to clipboard!</small>) : null}
                  </div>
                </CopyToClipboard>
              </div>) : (null)
            }
          </Col>
          <Col md={4}>
            <div className={css(styles.scoreContainer)}>
              <span className={css(styles.redText)}>{this.unopenedCardCount("red").toString()}</span>
              <span> - </span>
              <span className={css(styles.blueText)}>{this.unopenedCardCount("blue").toString()}</span>
            </div>
            <Button
              variant="light" size={"md"}
              onClick={this.endTurn} disabled={gameIsComplete}
              className={"lead", css(styles.boldCurrentTeamText, textColorStyle)}>
              {!gameIsComplete ? (turn + "'s turn") : (winner + " team wins!")}
            </Button>
          </Col>
          <Col md={3}>
            {showGif ? (<Image className={css(styles.gifImage)} src={gif} rounded fluid/>) : (null)}
            {!showGif && timerEnabled && !gameIsComplete ?
              (<div className={css(styles.timerContainer)}>
                <Button
                  variant="light" size={"md"}
                  className={css(textColorStyle, styles.timerButton)}>
                  <Countdown date={timerEndTime} renderer={timerRenderer}/>
                </Button>
              </div>) : (null)
            }
          </Col>
        </Row>
        <Row>
          <Board
            data={board}
            openCard={this.openCard}
            spyMasterMode={spyMasterMode}
            complete={gameIsComplete}/>
        </Row>
        <Row className={css(styles.bar)}>
          <Col md={3}>
            <Button variant="light" size={"sm"} onClick={this.nextGame}>Next Game</Button>
          </Col>
          <Col md={6}>
            <p className={css(styles.bottomText)} onClick={() => window.open("https://www.buymeacoffee.com/subtlecurrygame?ref=bottomBar", '_blank') }>
              Enjoy playing this game? <u>Buy the developer a chai  <FontAwesomeIcon icon={faMugHot} className={css(styles.chaiIcon)}/></u>
            </p>
          </Col>
          <Col md={3}>
            <div className={css(styles.rightButtonGroup)}>
              <FontAwesomeIcon className={css(styles.settingsIcon)} icon={faCog} onClick={() => this.setShowSettingsModal(true)}/>
              <ToggleButtonGroup type="radio" name="options" value={spyMasterMode ? 2 : 1} onChange={this.toggleSpyMaster}>
                <ToggleButton value={1} variant={"light"} size={"sm"}>Player</ToggleButton>
                <ToggleButton value={2} variant={"light"} size={"sm"}>SpyMaster</ToggleButton>
              </ToggleButtonGroup>
            </div>
          </Col>
        </Row>
        <AppWebSocket
          cableApp={CableApp}
          updateGame={this.updateGame}
        />
        <SettingsModal
          show={showSettingsModal}
          onHide={() => this.setShowSettingsModal(false)}
          timerEnabled={timerEnabled}
          timerDuration={timerDuration}
          updateRoomSettings={this.updateRoomSettings}

        />
      </Container>
    );
  }
}

App.propTypes = {
  data: PropTypes.string,
  turn: PropTypes.string,
  gameId: PropTypes.number,
};

const styles = StyleSheet.create({
  container: {
    paddingTop: "30px",
    display: "flex",
    flexDirection: "column",
  },
  scoreContainer: {
    textAlign: "center",
    paddingTop: "30px",
    marginBottom: "15px",
    fontSize: "56px",
    fontWeight: "300",
    lineHeight: "1.2",
  },
  bar: {
    display: "flex",
    textAlign: "center",
    marginBottom: "10px",
    marginTop: "10px",
    alignItems: "baseline",
    '@media only screen \
      and (min-device-width: 375px) \
      and (max-device-width: 896px) \
      and (orientation: landscape) ': {
      marginTop:'50px',
      marginBottom:'50px',
    }
  },
  topBar: {
    alignItems: "flex-end",
  },
  boldCurrentTeamText: {
    textTransform: "uppercase",
    fontWeight: "bold",
    marginTop: "0px"
  },
  nextTurnButtonHelpText: {
    marginTop: "10px",
    marginBottom: "0px",
  },
  timerText: {
    marginBottom: "0px",
    fontWeight: "500",
    marginLeft: "5px",
  },
  timerTextContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  timerColumn: {
    minHeight: "120px",
    display: "flex",
    alignItems: "flex-end",
    padding: "0px",
  },
  timerContainer: {
    float: "right",
    position: "relative",
    left: "20px",
  },
  timerButton: {
    cursor: "initial",
    ':hover': {
      backgroundColor: "white",
      borderColor: "white",
    }
  },
  redText: {
    color: "#ff584a",
  },
  blueText: {
    color: "#4a95ff",
  },
  gifContainer: {
    padding: "0px",
  },
  gifImage: {
    width: "auto",
    height: "150px",
  },
  clickToCopyContainer: {
    letterSpacing: "0.5px",
    marginBottom: "0px",
    color: "#6c757d",
    cursor: "pointer",
    fontSize: "14px",
    textAlign: "left",
    position: "relative",
    right: "20px",
  },
  rightButtonGroup: {
    marginRight: "70px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  settingsIcon: {
    marginRight: "10px",
    cursor: "pointer",
  },
  bottomText: {
    color: "#6c757d",
    fontSize: "14px",
    ':hover': {
      cursor: "pointer",
    }
  },
  chaiIcon: {
    marginLeft: "3px",
  }
});

export default App

// GRAVEYARD

// {!gameIsComplete && promptEndTurn ? (
//   <p className={"text-muted", css(styles.nextTurnButtonHelpText)}>
//     <small>Click 👆🏽to end turn</small>
//   </p>
// ) : (null)}
